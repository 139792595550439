<template>
  <div>
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="queryInfo.query" placeholder="请输入内容"  >
            <el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-table  border stripe
        :data="tableData"
        style="width: 100%">
        <el-table-column fixed
          prop="company_ID"
          label="公司ID"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="company_NAME"
          label="公司名称"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="init_DATE"
          label="发生日期"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="default_DATE"
          label="违约日期"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="bond_DEFAULT_NAME"
          label="债券违约"
          width="150">
        </el-table-column>
        <el-table-column fixed
          prop="bond_SNAME"
          label="债券简称"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column fixed
          prop="remark"
          label="违约描述"
          width="150">
        </el-table-column>
        <el-table-column fixed
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" >开始审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>
    <el-dialog title="审核" :visible.sync="recordDialogVisible"  width="40%" >
       <el-form ref="checkData" :model="checkData" label-width="80px" :rules="rules" class="demo-ruleForm" >
        <!-- <el-form-item label="公司名称"  >
          {{checkData.company_NAME}}
        </el-form-item>
        <el-form-item label="发生日期"  >
          {{checkData.init_DATE}}
        </el-form-item>
        <el-form-item label="违约日期" >
          {{checkData.default_DATE}}
        </el-form-item>
        <el-form-item label="债券违约" >
          {{checkData.bond_DEFAULT_NAME}}
        </el-form-item>
        <el-form-item label="违约描述" >
          {{checkData.remark}}
        </el-form-item> -->
        <el-form-item label="审核意见"  prop="process_OPINION">
            <el-input :autosize="{ minRows: 4}" type="textarea" v-model="checkData.process_OPINION"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="pass">审核通过</el-button>
          <el-button  @click="back('checkData')">退回</el-button>
        </el-form-item>
       </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'FlowTaskDefault',
  data () {
    return {
      recordDialogVisible: false,
      tableData: [],
      checkData: {
        company_ID: '',
        company_NAME: '',
        fiscal_YEAR: '',
        spt_SCORE: '',
        gics_NAME: '',
        city_INVEST_NAME: '',
        region_NAME: '',
        taSptDetall: [],
        process_OPINION: ''
      },
      queryInfo: {
        pagenum: 1,
        pagesize: 10
      },
      totle: 0,
      rules: {
        process_OPINION: [
          { required: false, message: '审核意见', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/default/flow', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    async  handleClick (row) {
      const { data: checkFlow } = await this.$http.get('check/default/flow/process', {
        params: { task_ID: row.task_ID }
      })
      if (checkFlow) {
        const { data: res } = await this.$http.get('query/default/flow/id', {
          params: { task_ID: row.task_ID }
        })
        this.checkData = res
        this.recordDialogVisible = true
      } else {
        this.$alert('此任务已被别人处理', '信息提示').then(() => {
          this.getTableData()
        }).catch(() => {
        })
      }
    },
    async pass () {
      const { data: checkFlow } = await this.$http.get('check/default/flow/process', {
        params: { task_ID: this.checkData.task_ID }
      })
      if (checkFlow) {
        await this.$http.post('flow/pass/default', {
          task_ID: this.checkData.task_ID, process_OPINION: this.checkData.process_OPINION
        })
        this.recordDialogVisible = false
      } else {
        this.$alert('此任务已被别人处理', '信息提示').then(() => {
          this.getTableData()
        }).catch(() => {
        })
      }
      this.getTableData()
    },
    async back (formName) {
      this.rules.process_OPINION[0].required = true
      let istrue
      // form验证
      this.$refs[formName].validate((valid) => {
        if (valid) {
          istrue = true
        } else {
          return false
        }
      })
      if (istrue) {
        const { data: checkFlow } = await this.$http.get('check/default/flow/process', {
          params: { task_ID: this.checkData.task_ID }
        })
        if (checkFlow) {
          await this.$http.post('flow/back/default', {
            task_ID: this.checkData.task_ID, process_OPINION: this.checkData.process_OPINION
          })
          this.recordDialogVisible = false
        } else {
          this.$alert('此任务已被别人处理', '信息提示').then(() => {
            this.getTableData()
          }).catch(() => {
          })
        }
        this.getTableData()
      }
    }
  },
  created () {
    this.getTableData()
  }
}
</script>

<style>
.el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    /*height:600px;*/
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
}
.el-table__body tr.current-row>td {
    color: #fff;
    background-color: #a2a4a7!important;
}
</style>
